<template>
  <UPopover
    v-model:open="open"
    :ui="{ width: 'min-w-60' }"
    class="flex items-center"
    :popper="{
      placement: 'bottom-end',
      strategy: 'absolute',
    }"
    @update:open="open = $event"
  >
    <template #panel>
      <div class="px-3 py-2 gap-2 flex items-center">
        <div
          v-for="color in FieldOptionColor" :key="color" :style="{ backgroundColor: color }"
          class="size-6 min-h-6 min-w-6 rounded cursor-pointer relative"
          @click="onChangeState(color)"
        >
          <Icon v-if="color === selectedColor" name="heroicons:check" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
    </template>
    <div class="rounded size-6 min-w-6 cursor-pointer" :style="{ backgroundColor: selectedColor }" />
  </UPopover>
</template>

<script lang="ts" setup>
import { FieldOptionColor, DEFAULT_FIELD_OPTION_COLOR } from '#field/constant'

defineProps({
  selectedColor: {
    type: String as PropType<FieldOptionColor>,
    required: true,
    default: DEFAULT_FIELD_OPTION_COLOR,
  },
})

const emit = defineEmits<{
  (e: 'select', color: FieldOptionColor): void
}>()

const open = ref(false)

const onChangeState = (color: FieldOptionColor) => {
  emit('select', color)
  open.value = false
}
</script>
